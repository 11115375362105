
import { defineComponent } from 'vue';
import Info from '@/components/info.vue';

const Error = defineComponent({
    components: {
        Info
    },
    props: {
        error: String
    },
    computed: {
        showError(): boolean {
            return this.error != null;
        }
    }
});
export default Error;
