
import { defineComponent, ref } from 'vue';
// import { LoadingIcon } from '@/assets/icons';
import Error from '@/components/error.vue';

const Loading = defineComponent({
    components: {
        // LoadingIcon,
        Error
    },
    data() {
        return {
            isCompleted: false as boolean,
            showLoading: false as boolean,
            errorMessage: undefined as undefined | string,
        }
    },
    props: {
        promise: Promise,
        loadingText: String,
    },
    watch: {
        promise(newPromise: Promise<unknown> | undefined) {
            this.setPromise(newPromise);
        }
    },
    mounted() {
        this.setPromise(this.promise);
    },
    methods: {
        setPromise(promise: Promise<unknown> | undefined) {
            if (promise == null) {
                this.isCompleted = true;
            } else {
                this.resetShowLoadingTimer();
                this.isCompleted = false;
                promise.then(x => {
                    this.isCompleted = true;
                }).catch(err => {
                    this.errorMessage = err.message ?? err;
                });
            }
        },

        resetShowLoadingTimer() {
            this.showLoading = false;

            // Do not show the loading until after a little while
            // this prevents the elements to flicker when loading is very short
            setTimeout(() => {
                this.showLoading = true
            }, 300);
        },
    },
});
export default Loading;
