import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info = _resolveComponent("info")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showError)
      ? (_openBlock(), _createBlock(_component_info, {
          key: 0,
          type: "error"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.error), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.showError)
      ? _renderSlot(_ctx.$slots, "default", { key: 1 })
      : _createCommentVNode("", true)
  ], 64))
}