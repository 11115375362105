import { store } from "@/store";
import Axios, { AxiosResponse } from "axios";

export class ApiBase {
    protected async get<TEntity>(action?: string, data?: any, useToken: boolean = true): Promise<TEntity> {
        return this.sendHttpRequest<TEntity>('get', this.getAddress(), action, data, useToken ? this.getHeaders() : undefined);
    }

    protected async post<TEntity>(action?: string, data?: any, useToken: boolean = true): Promise<TEntity> {
        return this.sendHttpRequest<TEntity>('post', this.getAddress(), action, data, useToken ? this.getHeaders() : undefined);
    }

    protected async delete(action?: string, data?: any, useToken: boolean = true): Promise<void> {
        return this.sendHttpRequest<void>('delete', this.getAddress(), action, data, useToken ? this.getHeaders() : undefined);
    }

    /**
     * Sends a put request (update) to bridge with a entity as update body.
     * @param entity The entity to send as with the put request (the update).
     * @param action Any action to use when sending the put request.
     */
    protected async put<TEntity>(entity: object, action?: string, useToken: boolean = true): Promise<TEntity> {
        return this.sendHttpRequest('put', this.getAddress(), action, entity, useToken ? this.getHeaders() : undefined);
    }

    /**
     * Sends a HTTP request to the selected address.
     * @param method The method used for the HTTP request.
     * @param address The endpoint address to send the request to.
     * @param controller Any controller (part of the url) to send the request to.
     * @param action Any controller action (part of the url) to send the request to.
     * @param data Any additional data that will be sent as part of the body.
     * @param headers Any additional headers that will be included in the request.
     */
    protected async sendHttpRequest<TReturn>(
        method: "get" | "delete" | "head" | "options" | "post" | "put" | "patch" | "purge" | "link" | "unlink",
        address: string,
        action?: string,
        data?: any,
        headers?: any): Promise<TReturn> {
        
        // Create the url
        const actionUrl = action == null ? '' : `/${action}`;
        let error = undefined as undefined | { code: number, message: string };

        // Send HTTP request and return the expected result
        const result = await Axios({
            url: `${address}${actionUrl}`,
            method,
            headers,
            data
        }).catch(err => {
            error = {
                code: err?.response?.status as number,
                message: err?.response?.message
            }
            if (err?.response?.status == 401) {
                // The user is not properly logged in,
                // Set state to force the user to login again.
                store.removeToken();
            } else {
                throw Error(err);
            }
        });

        // Throw error if an error occurred
        if (result == null) {
            throw { error };
        }

        return (result as AxiosResponse<any>).data as TReturn;
    }

    /**
     * Returns the address to the server.
     */
    protected getAddress() {
        return 'https://hannes-bok.azurewebsites.net/api';
    }

    /**
     * Gets the current session token.
     */
    protected getToken() {
        const token = store.getState().token;
        if (token == null) {
            throw new Error('No token available.');
        }

        return token;
    }

    protected getHeaders() {
        return {
            'Authorization': `Bearer ${this.getToken()}`
        };
    }
}