import api from "@/api/api";
import { StoreBase } from "./store-base";

export interface IStore extends Object {
    token?: string;
}

class Store extends StoreBase<IStore> {
    constructor() {
        super('hannes-bok');
    }

    protected data(): IStore {
        return {
            token: undefined,
        };
    }

    /**
     * Sets the current session token.
     * @param token The token to set.
     */
    setToken(token: string) {
        this.state.token = token;
    }

    /**
     * Removes the current token, logging out the user
     */
    removeToken() {
        if (this.state.token !== undefined) {
            this.state.token = undefined;
        }
        api.clearCache();
    }
}

export const store: Store = new Store();