import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", null, [
    _createVNode(_component_router_link, { to: "/add" }, {
      default: _withCtx(() => [
        _createVNode(_component_fa, { icon: "plus" })
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, {
      to: "/",
      class: "home-button"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_fa, { icon: "square-poll-vertical" })
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, { to: "/list" }, {
      default: _withCtx(() => [
        _createVNode(_component_fa, { icon: "table-list" })
      ]),
      _: 1
    })
  ]))
}