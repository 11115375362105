import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-abc0d3d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon" }
const _hoisted_2 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["info", _ctx.infoType])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.infoType == 'normal')
        ? (_openBlock(), _createBlock(_component_fa, {
            key: 0,
            icon: "circle-info"
          }))
        : _createCommentVNode("", true),
      (_ctx.infoType == 'warning')
        ? (_openBlock(), _createBlock(_component_fa, {
            key: 1,
            icon: "triangle-exclamation"
          }))
        : _createCommentVNode("", true),
      (_ctx.infoType == 'error')
        ? (_openBlock(), _createBlock(_component_fa, {
            key: 2,
            icon: "hexagon-exclamation"
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 2))
}