
import api from '@/api/api';
import { store } from '@/store';
import { defineComponent } from 'vue';
import Loading from '@/components/loading.vue';

const LoginView = defineComponent({
    components: {
        Loading
    },
    data() {
        return {
            loading: undefined as undefined | Promise<unknown>,
            email: undefined as undefined | string,
            password: undefined as undefined | string,
            error: undefined as undefined | string,
            loginButtonEnabled: true as boolean,
        }
    },
    methods: {
        async submit(event: Event) {
            this.loginButtonEnabled = false;
            this.error = undefined;
            if (this.email == null || this.password == null) {
                this.error = 'Du måste fylla i din e-postadress och ditt lösenord';
            } else {
                this.loading = api.authenticate(this.email, this.password).then(result => {
                    try {
                        if (result.token != null) {
                            store.setToken(result.token);
                        } else {
                            throw Error('Fel e-post eller lösenord');
                        }
                    } catch (err) {
                        if ((err as any)?.error?.code === 401) {
                            this.error = 'Fel e-post eller lösenord';
                        } else {
                            this.error = (err as any)?.error.message;
                        }
                    } finally {
                        this.loginButtonEnabled = true;
                    }
                });
            }
        }
    }
});
export default LoginView;
