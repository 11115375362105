import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42dc4fca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loading" }
const _hoisted_2 = {
  key: 0,
  class: "icon"
}
const _hoisted_3 = {
  key: 0,
  class: "text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!
  const _component_error = _resolveComponent("error")!

  return (_openBlock(), _createBlock(_component_error, { error: _ctx.errorMessage }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.isCompleted && _ctx.showLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_fa, {
                icon: "circle-notch",
                spin: ""
              }),
              (_ctx.loadingText != null)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.loadingText), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isCompleted)
          ? _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 8, ["error"]))
}