import { ApiBase } from "./apiBase";
import AuthenticationResult from '@/api/entities/authenticationResult';
import ReadBook from "./entities/readBook";
import { store } from "@/store";
import OpenLibraryBook from "./entities/openLibraryBook";

const OpenLibraryUrl = 'https://openlibrary.org/api/';

class Api extends ApiBase {

    /** Cache showing all read books */
    private readBooksCache: Map<string, ReadBook> = new Map();
    private readBooksCacheTime: Date | undefined = undefined;

    /**
     * Authenticates and login.
     * @param email The user's e-mail address to authenticate with.
     * @param password The user's password to authenticate with.
     */
    public async authenticate(email: string, password: string): Promise<AuthenticationResult> {
        return await this.post<AuthenticationResult>(`authenticate/${email}`, {
            password
        }, false);
    }

    /**
     * Adds a new read book to the database.
     * @param book The read book to add.
     */
    public async addBook(book: ReadBook): Promise<ReadBook> {
        const addedBook = await this.post<ReadBook>(`addbook`, book);

        // Add the book to the cache
        if (addedBook.id != null) {
            this.readBooksCache.set(addedBook.id, addedBook);
        }
        return addedBook;
    }

    /**
     * Updates an existing read book in the database.
     * @param book The read book to update.
     */
    public async updateBook(book: ReadBook): Promise<ReadBook> {
        const addedBook = await this.post<ReadBook>(`updatebook`, book);

        // Update the book cache
        if (addedBook.id != null) {
            this.readBooksCache.set(addedBook.id, addedBook);
        }
        return addedBook;
    }

    /**
     * Deletes the selected book from the database.
     * @param id The id of the book to delete.
     */
    public async deleteBook(id: string): Promise<void> {
        const addedBook = await this.delete(`deletebook/${id}`);

        // Update the book cache
        if (id != null) {
            this.readBooksCache.delete(id);
        }
        return addedBook;
    }

    /**
     * Returns the selected book.
     */
    public async getBook(id: string): Promise<ReadBook | undefined> {
        // Make sure the cache of books is not empty or has timed out
        const allBooks = await this.getAllBooks();
        return Array.from(this.readBooksCache.values()).find(x=> x.id === id);
    }

    /**
     * Returns all read books.
     */
    public async getAllBooks(): Promise<ReadBook[]> {        
        if (this.cacheHasTimedout()) {
            const allReadBooks = await this.get<ReadBook[]>(`getallbooks`);
            allReadBooks.forEach(readBook => {
                if (readBook.id != null) {
                    this.readBooksCache.set(readBook.id, readBook);
                }
            });
            this.readBooksCacheTime = new Date();
        }

        return Array.from(this.readBooksCache.values());
    }

    /**
     * Searches OpenLibrary for books matching the isbn.
     * @param isbn The isbn to search for.
     */
    public async findBookByIsbn(isbn?: string): Promise<OpenLibraryBook | undefined> {
        if (isbn == null || isbn.length === 0) { return undefined; }
        const cleanIsbn = isbn.replaceAll(/-/g, '').trim();

        // Send search request to OpenLibrary
        const url = `${OpenLibraryUrl}books?bibkeys=ISBN:${cleanIsbn}&jscmd=data&format=json`;
        const openLibraryResult = await this.sendHttpRequest<any>('get', url);

        // Return the first result (in the future the user could be made select from multiple results if any)
        for (const key in openLibraryResult) {
            return openLibraryResult[key] as OpenLibraryBook;
        }

        // No book where found
        return undefined;
    }

    /**
     * Clears the cache and forces all books to be reloaded.
     */
    public clearCache(): void {
        this.readBooksCache.clear();
        this.readBooksCacheTime = undefined;
    }

    private cacheHasTimedout() {
        const currentDate = new Date();
        const cacheTimeout = new Date(currentDate.getTime() - (10 * 60 * 1000));
        
        const result = this.readBooksCache.size === 0
            || this.readBooksCacheTime == null
            || this.readBooksCacheTime.getTime() < cacheTimeout.getTime();
        
        return result;
    }
}

const api = new Api();
export default api;
