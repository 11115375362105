
import { defineComponent, ref } from 'vue';
import MainMenu from '@/components/main-menu.vue';
import { store } from './store';
import LoginView from '@/views/login.vue';

const App = defineComponent({
    components: {
        MainMenu,
        LoginView
    },
    computed: {
        loginNeeded() {
            return store.getState().token == null;
        },
    }
});
export default App;
