
import { defineComponent } from 'vue';
// import { AddIcon, HomeIcon, ListIcon, SearchIcon } from '@/assets/icons';

const MainMenu = defineComponent({
    components: {
        // AddIcon,
        // HomeIcon,
        // ListIcon,
        // SearchIcon
    },
    data() {
        return {
        }
    },
    methods: {
    }
});
export default MainMenu;
