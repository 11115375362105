
import { defineComponent } from 'vue';
// import { AboutIcon, WarningIcon, ErrorIcon } from '@/assets/icons';

const Info = defineComponent({
    components: {
        // AboutIcon,
        // WarningIcon,
        // ErrorIcon
    },
    props: {
        type: String,
    },
    computed: {
        infoType(): string {
            // Return warning, error or normal depening on type prop
            switch (this.type?.toLowerCase()) {
                case 'warning':
                case 'error':
                    return this.type;
                default:
                    return 'normal';
            }
        }
    }
});
export default Info;
